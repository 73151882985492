// extracted by mini-css-extract-plugin
export var solutionsHdr1 = "headers-module--solutionsHdr1--BwfjC";
export var content = "headers-module--content--jk9XW";
export var automotive = "headers-module--automotive--30Sse";
export var insurance = "headers-module--insurance--qlJ9l";
export var media = "headers-module--media--Iy2N6";
export var retail = "headers-module--retail--JtlyG";
export var realestate = "headers-module--realestate--NZVyO";
export var data = "headers-module--data--Po5Dx";
export var creative = "headers-module--creative--7jJmg";
export var icon = "headers-module--icon--Hvf4T";
export var fadeIn = "headers-module--fadeIn--UiVkY";
export var slideIn = "headers-module--slideIn--G97sH";